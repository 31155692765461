import { Button, Card, Col, Divider, Form, Input, Row, Typography } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import BorderTable from "src/components/BorderTable";
import CustomForm from "src/components/CustomForm";
import TextConstants from "src/constants/TextConstants";
import { getBMSetupData } from "src/store/actions/bms";

const renderFloorLinkedData = (floorLinked) => {
  if (!floorLinked || floorLinked.length === 0) {
    return "-";
  }

  const groupedData = floorLinked.reduce((acc, item) => {
    const {
      companyId,
      companyName,
      facilityId,
      facilityName,
      floorId,
      floorName,
    } = item;

    // Find or create the company group
    let company = acc.find((c) => c.companyId === companyId);
    if (!company) {
      company = { companyId, companyName, facilities: [] };
      acc.push(company);
    }

    // Find or create the facility group
    let facility = company.facilities.find((f) => f.facilityId === facilityId);
    if (!facility) {
      facility = { facilityId, facilityName, floors: [] };
      company.facilities.push(facility);
    }

    // Add the floor to the facility
    facility.floors.push({ floorId, floorName });

    return acc;
  }, []);

  return groupedData.map((company) => {
    return (
      <Card key={company.companyId} style={{ marginTop: 10 }}>
        <Typography.Text>
          <strong>{TextConstants.Common.CompanyName}: </strong>
          {company.companyName}
        </Typography.Text>
        {company.facilities.map((facility) => (
          <div
            key={facility.facilityId}
            style={{ marginLeft: 10, marginBottom: 20 }}
          >
            <Typography.Text>
              {TextConstants.NewFacility.FacilityName}: {facility.facilityName}
            </Typography.Text>
            {facility.floors.map((floor) => (
              <p
                key={floor.floorId}
                style={{ marginLeft: 20, marginBottom: 8 }}
              >
                <Typography.Text>
                  {TextConstants.FacilityFloors.FloorName}: {floor.floorName}{" "}
                </Typography.Text>
                <Input
                  size="sm"
                  className="access-id-input"
                  addonBefore={
                    <Typography.Text style={{ color: "white" }}>
                      Access ID
                    </Typography.Text>
                  }
                  value={`${facility.facilityId}:${floor.floorId}`}
                />
              </p>
            ))}
          </div>
        ))}
      </Card>
    );
  });
};

const BMsGlobalSearch = () => {
  const dispatch = useDispatch();
  const { loading, bmSetupData } = useSelector((state) => state.Bms);

  const columns = [
    {
      title: "ID",
      dataIndex: "bmId",
      key: "bmId",
      width: "15%",
    },
    {
      title: TextConstants.Common.BMFWVersion,
      dataIndex: "bmFWVersion",
      key: "bmFWVersion",
      width: "15%",
      render: (_, record) => {
        return record.configurations?.bmVersion || "-";
      },
    },
    {
      title: "TG FW",
      dataIndex: "tgFW",
      key: "tgFW",
      width: "15%",
      render: (_, record) => {
        return record.configurations?.tgVersion || "-";
      },
    },
    {
      title: TextConstants.GlobalBMSearch.SetupDataInfo,
      dataIndex: "floorLinked",
      key: "floorLinked",
      render: (val) => {
        return renderFloorLinkedData(val);
      },
    },
  ];

  const onFinish = (values) => {
    dispatch(getBMSetupData({ bmId: values.bmId }));
  };

  return (
    <React.Fragment>
      <Row gutter={[24, 0]}>
        <Col xs={{ span: 24 }}>
          <Card
            className="common-card tablespace"
            title={TextConstants.Pages.GlobalBMSearch}
          >
            <Row>
              <Col span={24}>
                <CustomForm
                  name="basic"
                  style={{
                    maxWidth: 600,
                  }}
                  initialValues={{
                    bmId: "",
                  }}
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <Form.Item
                    label="BMID"
                    name="bmId"
                    rules={[
                      {
                        required: true,
                      },
                      {
                        max: 255,
                        message:
                          TextConstants.Common.MaxLengthWarningMessage.replace(
                            "{max}",
                            "255"
                          ),
                      },
                    ]}
                  >
                    <Input disabled={loading} />
                  </Form.Item>
                  <Form.Item label={null} className="d-flex">
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      disabled={loading}
                    >
                      {TextConstants.Common.Submit}
                    </Button>
                  </Form.Item>
                </CustomForm>
              </Col>
            </Row>
            <Divider orientation="left">
              {TextConstants.GlobalBMSearch.SearchResult}
            </Divider>
            <BorderTable
              loading={loading}
              columns={columns}
              dataSource={bmSetupData && bmSetupData.bmId ? [bmSetupData] : []}
              rowKey={"bmId"}
              showPagination={false}
            />
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BMsGlobalSearch;
